var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('k-crud-page',{attrs:{"show-top":false},scopedSlots:_vm._u([{key:"resultlist",fn:function(){return [_c('k-crud-table',{attrs:{"headers":_vm.columns,"items":_vm.cruddataMX.dataList,"value":_vm.cruddataMX.pagination.current,"total":_vm.cruddataMX.pagination.total},on:{"pageChanged":function (page) {
            _vm.crudPaginationMX().change(page);
          }},scopedSlots:_vm._u([{key:"roleVos",fn:function(ref){
          var item = ref.item;
return [_c('k-switch',{attrs:{"readonly":!_vm.hasPermission(['schedule.task.status']),"value":item.status,"true-value":_vm.yesOrNo('YES', _vm.dictData.YesNoStatusEnum),"false-value":_vm.yesOrNo('NO', _vm.dictData.YesNoStatusEnum),"label":item.status === _vm.yesOrNo('YES', _vm.dictData.YesNoStatusEnum)
                ? '启用'
                : '停用'},on:{"change":function($event){return _vm.oprTask(item)}}})]}},{key:"roleVos1",fn:function(ref){
                var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.corn.taskExecution))])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("任务列表")]),_c('v-spacer'),(_vm.hasPermission(['schedule.task.add']))?_c('k-primary-button',{attrs:{"icon":"mdi-plus","text":"新增定时生成任务"},on:{"click":function($event){_vm.crudActionMX().newItem(_vm.crud.default, _vm.onOpenEdit(_vm.crud.default))}}}):_vm._e(),_c('k-crud-dialog',{attrs:{"title":_vm.crud.title},on:{"save":function () {
                  _vm.crudActionMX().saveItem();
                },"close":function () {
                  _vm.crudActionMX().closeEditingPage(_vm.crud);
                }},scopedSlots:_vm._u([{key:"dialogContent",fn:function(){return [_c('v-form',{ref:_vm.crud.form.ref},[_c('div',{staticClass:"d-flex flex-column"},[_c('k-text-input',{staticClass:"my-4",attrs:{"placeholder":"请输入任务名称","label":"任务名称","rules":[_vm.globalRules.empty]},model:{value:(_vm.cruddataMX.data.taskName),callback:function ($$v) {_vm.$set(_vm.cruddataMX.data, "taskName", $$v)},expression:"cruddataMX.data.taskName"}}),_c('k-select',{staticClass:"my-4",attrs:{"items":_vm.analysisItem,"rules":[_vm.globalRules.empty],"label":"任务类型"},model:{value:(_vm.cruddataMX.data.analysisItemsUuid),callback:function ($$v) {_vm.$set(_vm.cruddataMX.data, "analysisItemsUuid", $$v)},expression:"cruddataMX.data.analysisItemsUuid"}}),_c('div',{staticClass:"d-flex my-4"},[_c('k-select',{attrs:{"items":_vm.dictData.TaskExecutionEnum,"item-text":"msg","item-value":"code","label":"执行周期","input-width":_vm.cruddataMX.data.corn.execution === '03'
                            ? '344px'
                            : '130px',"rules":[_vm.globalRules.empty]},on:{"change":_vm.executionChange},model:{value:(_vm.cruddataMX.data.corn.execution),callback:function ($$v) {_vm.$set(_vm.cruddataMX.data.corn, "execution", $$v)},expression:"cruddataMX.data.corn.execution"}}),(_vm.cruddataMX.data.corn.execution !== '03')?_c('k-select',{staticClass:"ml-2",attrs:{"rules":_vm.cruddataMX.data.corn.execution === '03'
                            ? []
                            : [_vm.globalRules.multiple],"item-text":"msg","item-value":"code","items":_vm.showDays(_vm.cruddataMX.data.corn.execution),"disabled":_vm.cruddataMX.data.corn.execution === '03',"input-width":"200px","multiple":""},model:{value:(_vm.cruddataMX.data.corn.days),callback:function ($$v) {_vm.$set(_vm.cruddataMX.data.corn, "days", $$v)},expression:"cruddataMX.data.corn.days"}}):_vm._e()],1),_c('k-text-input',{staticClass:"my-4",attrs:{"placeholder":"请输入执行时间","label":"执行时间","type":"time","rules":[_vm.globalRules.empty]},model:{value:(_vm.cruddataMX.data.corn.time),callback:function ($$v) {_vm.$set(_vm.cruddataMX.data.corn, "time", $$v)},expression:"cruddataMX.data.corn.time"}}),_c('div',{staticClass:"d-flex my-4 ml-16"},[_c('div',[_vm._v("状态:")]),_c('v-switch',{staticClass:"mt-n2 ml-2",attrs:{"true-value":_vm.yesOrNo('YES', _vm.dictData.YesNoStatusEnum),"false-value":_vm.yesOrNo('NO', _vm.dictData.YesNoStatusEnum),"color":"primary"},model:{value:(_vm.cruddataMX.data.status),callback:function ($$v) {_vm.$set(_vm.cruddataMX.data, "status", $$v)},expression:"cruddataMX.data.status"}})],1)],1)])]},proxy:true}]),model:{value:(_vm.crud.switch),callback:function ($$v) {_vm.$set(_vm.crud, "switch", $$v)},expression:"crud.switch"}})],1)]},proxy:true},{key:"actions",fn:function(ref){
                            var item = ref.item;
return [(_vm.hasPermission(['schedule.task.edit']))?_c('k-table-warning-btn',{staticClass:"mr-2",attrs:{"text":"编辑"},on:{"click":function($event){_vm.crudActionMX().editItem(item, function () { return _vm.onOpenEdit(item); })}}}):_vm._e(),(_vm.hasPermission(['schedule.task.delete']))?_c('k-table-error-btn',{attrs:{"text":"删除"},on:{"click":function($event){_vm.crudActionMX().deleteItem(item)}}}):_vm._e()]}}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }