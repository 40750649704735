<template>
  <div>
    <k-crud-page :show-top="false">
      <template #resultlist>
        <k-crud-table
          :headers="columns"
          :items="cruddataMX.dataList"
          :value="cruddataMX.pagination.current"
          :total="cruddataMX.pagination.total"
          @pageChanged="
            (page) => {
              crudPaginationMX().change(page);
            }
          "
        >
          <template #roleVos="{ item }">
            <k-switch
              :readonly="!hasPermission(['schedule.task.status'])"
              :value="item.status"
              :true-value="yesOrNo('YES', dictData.YesNoStatusEnum)"
              :false-value="yesOrNo('NO', dictData.YesNoStatusEnum)"
              :label="
                item.status === yesOrNo('YES', dictData.YesNoStatusEnum)
                  ? '启用'
                  : '停用'
              "
              @change="oprTask(item)"
            />
          </template>
          <template #roleVos1="{ item }">
            <div>{{ item.corn.taskExecution }}</div>
          </template>
          <template #top>
            <v-toolbar flat>
              <v-toolbar-title>任务列表</v-toolbar-title>
              <v-spacer></v-spacer>
              <k-primary-button
                v-if="hasPermission(['schedule.task.add'])"
                icon="mdi-plus"
                text="新增定时生成任务"
                @click="
                  crudActionMX().newItem(crud.default, onOpenEdit(crud.default))
                "
              />
              <k-crud-dialog
                v-model="crud.switch"
                :title="crud.title"
                @save="
                  () => {
                    crudActionMX().saveItem();
                  }
                "
                @close="
                  () => {
                    crudActionMX().closeEditingPage(crud);
                  }
                "
              >
                <template #dialogContent>
                  <v-form :ref="crud.form.ref">
                    <div class="d-flex flex-column">
                      <k-text-input
                        v-model="cruddataMX.data.taskName"
                        placeholder="请输入任务名称"
                        label="任务名称"
                        class="my-4"
                        :rules="[globalRules.empty]"
                      />
                      <k-select
                        v-model="cruddataMX.data.analysisItemsUuid"
                        :items="analysisItem"
                        class="my-4"
                        :rules="[globalRules.empty]"
                        label="任务类型"
                      />
                      <div class="d-flex my-4">
                        <k-select
                          v-model="cruddataMX.data.corn.execution"
                          :items="dictData.TaskExecutionEnum"
                          item-text="msg"
                          item-value="code"
                          label="执行周期"
                          :input-width="
                            cruddataMX.data.corn.execution === '03'
                              ? '344px'
                              : '130px'
                          "
                          :rules="[globalRules.empty]"
                          @change="executionChange"
                        />
                        <k-select
                          v-if="cruddataMX.data.corn.execution !== '03'"
                          v-model="cruddataMX.data.corn.days"
                          :rules="
                            cruddataMX.data.corn.execution === '03'
                              ? []
                              : [globalRules.multiple]
                          "
                          item-text="msg"
                          item-value="code"
                          :items="showDays(cruddataMX.data.corn.execution)"
                          :disabled="cruddataMX.data.corn.execution === '03'"
                          input-width="200px"
                          class="ml-2"
                          multiple
                        />
                      </div>
                      <k-text-input
                        v-model="cruddataMX.data.corn.time"
                        placeholder="请输入执行时间"
                        label="执行时间"
                        class="my-4"
                        type="time"
                        :rules="[globalRules.empty]"
                      />
                      <div class="d-flex my-4 ml-16">
                        <div>状态:</div>
                        <v-switch
                          v-model="cruddataMX.data.status"
                          :true-value="yesOrNo('YES', dictData.YesNoStatusEnum)"
                          :false-value="yesOrNo('NO', dictData.YesNoStatusEnum)"
                          class="mt-n2 ml-2"
                          color="primary"
                        />
                      </div>
                    </div>
                  </v-form>
                </template>
              </k-crud-dialog>
            </v-toolbar>
          </template>
          <template #actions="{ item }">
            <k-table-warning-btn
              v-if="hasPermission(['schedule.task.edit'])"
              class="mr-2"
              text="编辑"
              @click="crudActionMX().editItem(item, () => onOpenEdit(item))"
            />
            <k-table-error-btn
              v-if="hasPermission(['schedule.task.delete'])"
              text="删除"
              @click="crudActionMX().deleteItem(item)"
            />
          </template>
        </k-crud-table>
      </template>
    </k-crud-page>
  </div>
</template>
<script>
import lodash from 'lodash';

export default {
  components: {},
  data() {
    return {
      lodash,
      testvalue: '',
      crud: {
        name: '定时生成任务',
        title: '', // 在Mixin中被修改
        switch: false,
        uploading: false,
        loading: false,
        form: {
          ref: 'accountModalForm',
        },
        select: {
          moudle: {
            loading: false,
            current: 1,
            defaultPageSize: 10,
            roles: [],
            targets: [],
            messageList: [],
          },
        },
        default: {
          uuid: null,
          taskName: '',
          analysisItemsUuid: '',
          status: '02',
          corn: {
            execution: '01', // 周期 [枚举 TaskExecutionEnum]
            days: ['1'], //天数 没有天数默认为[] 周期为每月-天数枚举DayOfMonthEnum 周期为每周-天数枚举DayOfWeekEnum 周期为每天-天数枚举[]  每月和每周必须存在天数
            time: '', //时间 HH:MM格式
          },
        },
      },
      columns: [
        { text: '序号', value: 'indexNum', sortable: false, algin: 'center' },
        {
          text: '任务类型',
          value: 'analysisItemsName',
          sortable: false,
          algin: 'center',
        },
        {
          text: '任务名称',
          value: 'taskName',
          algin: 'center',
          sortable: false,
        },
        {
          text: '任务执行周期',
          value: 'roleVos1',
          algin: 'center',
          sortable: false,
        },
        {
          text: '任务状态',
          value: 'roleVos',
          algin: 'center',
          sortable: false,
        },
        { text: '操作', value: 'actions', sortable: false, algin: 'center' },
      ],
      roles: [],
      targets: [],
      moudleList: [],
      analysisItem: [],
      dictData: {},
    };
  },
  computed: {},
  mounted() {
    console.log('----', this.cruddataMX, this);
  },
  created() {
    this.getAnalysisItems();
    this.crudInitMX(
      this.crud,
      {
        list: this.$api.taskManage.task.getTaskGeneration,
        add: (param) => {
          // param.execution = this.execution;
          // param.day = this.day;
          return this.$api.taskManage.task.addTaskGeneration(param);
        },
        edit: (param) => {
          // param.execution = this.execution;
          // param.day = this.day;
          return this.$api.taskManage.task.editTaskGeneration(param);
        },
        delete: this.$api.taskManage.task.deleteTaskGeneration,
      },
      {},
      {}
    );
  },
  methods: {
    executionChange() {
      this.cruddataMX.data.corn.days = [];
    },
    showDays(code) {
      if (code === '01') {
        return this.dictData.DayOfMonthEnum;
      } else if (code === '02') {
        return this.dictData.DayOfWeekEnum;
      }
    },
    yesOrNo(flag, dict) {
      if (dict && dict.length) {
        return dict.find((item) => item.name === flag).code;
      } else {
        console.error('dict type Array');
      }
    },
    async onOpenEdit(item) {
      this.crud.switch = true;
      this.$api.taskManage.task.taskType().then(({ data, code }) => {
        if (code === 200) {
          this.analysisItem = data;
        }
      });
      if (item.uuid) {
        await this.$api.taskManage.task
          .taskInfo(item)
          .then(({ data, code }) => {
            if (code === 200) {
              if (data && data[0]) {
                data[0].corn.days = data[0].corn.days || [];
              }
              this.$set(this.cruddataMX, 'data', data[0]);
            }
          });
      }
      // this.setWeekList(this.execution);
      // setTimeout(() => {
      //   this.execution = item.execution;
      //   this.day = item.day;
      // }, 200);
    },

    // 任务的启停用
    oprTask(item) {
      const query = {
        uuid: item.uuid,
        status: item.status === '01' ? '02' : '01',
      };
      this.$api.taskManage.task
        .changeStatus(query)
        .then(({ code, message }) => {
          if (code === 200) {
            this.$alert.success(message);
            this.crudActionMX().list();
          }
        });
    },
    // 下拉框值获取
    getAnalysisItems() {
      this.$api.taskManage.task.getDict().then((res) => {
        this.dictData = res.data[0];
      });
    },
  },
};
</script>
